:root {
  // Font Family
  --font-primary: "Inter",sans-serif;
  --font-secondary: "Inter",sans-serif;
  --font-webarq-icon: "Webarq-Icons";

  // Shadow
  --shadow-small: 0px 4px 6px 2px rgba(0, 0, 0, 0.05);
  --shadow-medium: 0px 4px 10px 4px rgba(0, 0, 0, 0.05);
  --shadow-large: 0px 4px 20px 10px rgba(0, 0, 0, 0.05);
}
